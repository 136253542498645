html, body {
  margin: 0;
  padding: 0;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  font-family: $f-stack;
  font-size: 14px;
  background-color: $c-black;
  color: #fff;
  overflow-x: hidden;

  @include selection {
    background-color: rgba($c-accent, 0.9);
  }
}

::-webkit-scrollbar {
  width: 0px;
}

a {
  -webkit-tap-highlight-color: transparent;
}


%dots {
  $bg-color: #111;
  $dot-color: rgba(#dadada, .2);

  $dot-size: 1px;
  $dot-space: 20px;
  background: linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
              linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
              $dot-color;
  background-size: $dot-space $dot-space;
}
%dots--white {
  $bg-color: #fff;
  $dot-color: rgba(#dadada, .8);

  $dot-size: 1px;
  $dot-space: 20px;
  background: linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
              linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
              $dot-color;
  background-size: $dot-space $dot-space;
}

%dots-png {
  background-image: url(../img/dot.png);
  background-position: 5px 5px;
  background-size: 20px 20px;
}

.svg-to-inject {
  visibility: hidden;
}
.replaced-svg {
  visibility: visible;
}