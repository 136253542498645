.section {
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @include tablet {
    max-height: none;
  }
  @include mobile {
    min-height: auto;
    max-height: none !important;

  }

  &--white {
    background-color: #fff;
    color: $c-black;
  }
}

.overlay {
  position: fixed;
  background-color: #090909;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 6;
  pointer-events: none;
  transform: translateY(-100%);
  box-sizing: border-box;
  will-change: transform;

  @include tablet {
    display: none;
    visibility: hidden;
    animation: none !important;
  }

  &--white {
    color: #ececec;
  }

  &.down {
    animation: flyup 1.2s ease-in-out;
  }
  &.up {
    animation: flydown 1.2s ease-in-out;
  }

  &--alt {
    background-color: #f7f7f7;
  }
}

// fullpage
  .fp-responsive {

    .section {
      height: auto !important;
    }
    .overlay {
      display: none;
    }
  }
  #fp-nav {
    z-index: 3;

    &.right {
      right: 20px;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        width: 80px;
        list-style: none;
        margin-bottom: 6px;

        a {
          display: block;
          padding-top: 7px;
          padding-bottom: 7px;

          &:hover {

            span {
              background-color: #fff;
            }
          }

          &.active {
            
            span {
              max-width: 80px;
              background-color: #fff;
            }

            .fp-nav--alt & span {
              // background-color: #2d2c37;
            }
          }

          span {
            display: block;
            width: 80px;
            max-width: 20px;
            height: 2px;
            background-color: #808080;
            transition: all .3s ease-in-out;
          }
        }
      }
    }

    &.fp-nav--alt {
      
      a {

        span {
          background-color: #dcdcdc;
        }

        &:hover {

          span {
            background-color: #2d2c37;
          }
        }
      }
      a.active {

        span {
          background-color: #2d2c37;
        }
      }
    }
  }
// eof fullpage
