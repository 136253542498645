.tech {
  @extend %dots--white;
  background-color: #bbb;

  @include tablet {
    padding-top: 50px;
  }
  
  canvas {

    @include mobile {
      display: none;
    }
  }

  &__title {
    position: relative;
    display: table;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 22px;
    font-size: 42px;
    font-weight: 900;
    z-index: 1;
    text-transform: uppercase;

    @include mobile {
      font-size: 24px;
    }
  }
  &__title-line {
    position: absolute;
    width: 105%;
    width: calc(100% + 10px);
    height: 10px;
    left: -5px;
    bottom: 6px;
    background-color: $c-accent;
    z-index: -1;

    @extend %shift-right;
    opacity: 0;
    transition: all .3s .2s;

    .animate & {
      opacity: 1;
      transform: none;
    }

    @include mobile {
      bottom: 0;
    }
  }
  &__subtitle {
    display: block;
    max-width: 180px;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #2d2c37;

    @extend %shift-bottom;
      opacity: 0;
      transition: all .3s .3s;

      .animate & {
        opacity: 1;
        transform: none;
      }

    @include tablet {
      margin-bottom: 20px;
    }
  }
  &__grid {
    display: flex;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 32px;
    padding-bottom: 34px;

    @include tablet {
      flex-wrap: wrap;
    }
    @include mobile {
      background-color: transparent;
    }
  }
  &__item {
    width: 25%;
    padding: 0 40px;
    flex-shrink: none;
    text-align: center;

    @extend %shift-bottom;
    opacity: 0;
    transition: all .3s .15s;

    &:nth-of-type(2) {
      transition-delay: .25s;
    }
    &:nth-of-type(3) {
      transition-delay: .35s;
    }
    &:nth-of-type(4) {
      transition-delay: .45s;
    }


    .animate & {
      opacity: 1;
      transform: none;
    }

    @include tablet {
      width: 50%;
      margin-bottom: 64px;
    }
    @include mobile {
      width: 100%;
      margin-bottom: 50px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
  &__item-icon {
    margin-bottom: 32px;
    user-select: none;
  }
  &__item-title {
    position: relative;
    font-size: 24px;
    margin-bottom: 30px;
    letter-spacing: 0.2px;
    font-weight: 900;

    @include mobile {
      max-width: 180px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -16px;
      display: block;
      width: 40px;
      height: 3px;
      background-color: $c-accent;
      transform: translateX(-50%);
    }
  }
  &__item-text {
    font-size: 14px;
    color: #2d2c37;
    line-height: 1.5;
    letter-spacing: 0.2px;

    @include mobile {
      max-width: 180px;
    }
  }
}