// breakpoints
  $mobile-width: 320px;
  $small-width: 576px;
  $tablet-width: 640px;
  $laptop-width: 1280px;
  $desktop-width: 992px;
// eof breakpoints

@mixin desktop {  // * -> 992
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin laptop {  // 1200 -> 0
  @media (max-width: #{$laptop-width - 1px}){
    @content;
  }
}
@mixin laptop-plus {  // 1200 -> *
  @media (min-width: #{$laptop-width}){
    @content;
  }
}

@mixin tablet {  // 991 -> 0
  @media (max-width: #{$laptop-width - 1px}){
    @content;
  }
}

@mixin mobile { // 767 -> 0
  @media (max-width: #{$tablet-width - 1px}){
    @content;
  }
}

@mixin small { // 576  -> 0
  @media (max-width: #{$small-width - 1px}){
    @content;
  }
}

@mixin selection {
  & ::selection {
    @content;
  }
  & ::-moz-selection {
    @content;
  }
}
