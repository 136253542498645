%shift-top {
  transform: translateY(-65px);
  transition: all .5s;
  transition-delay: .2s;
}
%shift-bottom {
  transform: translateY(65px);
  transition: all .5s;
  transition-delay: .2s;
}
%shift-left {
  transform: translateX(-65px);
  transition: all .5s;
  transition-delay: .2s;
}
%shift-right {
  transform: translateX(65px);
  transition: all .5s;
  transition-delay: .2s;
}



@keyframes jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  75% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes swing {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-3px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp2 {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight2 {
  from {
    opacity: 0;
    transform: translate3d(75px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft2 {
  from {
    opacity: 0;
    transform: translate3d(-75px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes flyup {
  0% {
    transform: translateY(100%);
  }
  43% {
    transform: translateY(0%);
  }
  65% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes flydown {
  0% {
    transform: translateY(-100%);
  }
  43% {
    transform: translateY(0%);
  }
  65% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes reveal-top {
  from {
    margin-top: -100px;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes reveal-bottom {
  from {
    margin-bottom: -100px;
    opacity: 0;
  }
  to {
    margin-bottom: 0;
    opacity: 1;
  }
}