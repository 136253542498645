.header {
  $h: &;

  position: fixed;
  width: 100%;
  padding: 0 80px;
  top: 40px;
  left: 0;
  z-index: 6;
  box-sizing: border-box;
  animation: reveal-top .5s .5s linear forwards;
  margin-top: -100px;
  transition: all .5s;

  @include tablet {
    padding: 0 20px;
  }
  @media (max-width: 1025px) {
    // background-color: #fff;
    background-color: $c-black;
    top: 0;
    padding-top: 12px;
    padding-bottom: 12px;

    .menu-opened & {
      padding-top: 24px;
      background-color: transparent !important;
    }
  }
  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
    // top: 0;
    // padding-top: 12px;
    // padding-bottom: 12px;
  }

  &--alt {

    @media (max-width: 1025px) {
      background-color: #fff;
    }
  }


  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {

    path,
    rect {
      fill: $c-accent;
      transition: all .3s;
    }

    &--white {

      path,
      rect {
        fill: #fff;
      }
    }
  }
  &__btn {
    position: relative;
    display: flex;
    cursor: pointer;
    min-height: 21px;

    &:hover {

      #{$h}__btn-part {
        background-color: $c-red;
      }
    }

    &--alt {

      #{$h}__btn-label {
        color: $c-black;
      }
      #{$h}__btn-part {
        background-color: $c-black;
      }
    }
  }
  &__btn-label {
    position: absolute;
    right: calc(100%);
    margin-right: 20px;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;

    transition: all .3s;

    .header__btn--active & {
      transform: translateY(16px);
      opacity: 0;
    }

    .menu-opened & {
      color: #fff !important;
    }

    &--alt {
      transform: translateY(-16px);
      opacity: 0;

      .header__btn--active & {
        opacity: 1;
        transform: none;
      }
    }

    @include mobile {
      display: none;
    }
  }
  &__btn-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__btn-part {
    width: 40px;
    height: 2px;
    margin-bottom: 5px;
    background-color: #fff;
    transition: all .5s;

    .header__btn--active & {
      max-width: 20px !important;
      margin-bottom: 0;
    }

    .menu-opened & {
      background-color: #fff !important;
    }

    &:nth-of-type(1) {

      .header__btn--active & {
        transform: translateY(5px) rotate(45deg);
      }
    }
    &:nth-of-type(2) {
      max-width: 30px;

      .header__btn--active & {
        opacity: 0;
      }
    }

    &:nth-of-type(3) {
      max-width: 20px;

      .header__btn--active & {
        transform: translateY(0) rotate(-0);
        transform: translateY(1px) rotate(-45deg);
      }
    }
  }
}
