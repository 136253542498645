.menu {
  $m: &;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: $c-accent;
  border: 10px solid #fff;

  opacity: 0;
  pointer-events: none;
  z-index: -2;
  transition: all .4s;

  @include mobile {
    border: 4px solid #fff;
  }

  @include tablet {
    overflow: auto;
  }

  &__inner {
    flex-shrink: 1;

    @include tablet {
      flex-shrink: 0;
    }
  }
  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      flex-shrink: 0;
    }
    @include mobile {
      padding-top: 40px;
      padding-bottom: 40px;
    }

  }
  &__list {
    margin: 0;
    padding: 0;
  }
  &__item {
    margin-bottom: 34px;
    list-style: none;
    text-align: center;
    
    transform: translateY(-20px);
    transition-delay: .1s;
    transition: all .3s;
    opacity: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
    &:nth-of-type(2) {
      transition-delay: .2s;
    }
    &:nth-of-type(3) {
      transition-delay: .3s;
    }
    &:nth-of-type(4) {
      transition-delay: .4s;
    }
    &:nth-of-type(5) {
      transition-delay: .5s;
    }
    &:nth-of-type(6) {
      transition-delay: .6s;
    }
    #{$m}.is-visible & {
      opacity: 1;
      transform: none;
    }
  }
  &__link {
    position: relative;
    display: inline-block;
    padding: 0 16px;
    font-size: 42px;
    text-transform: uppercase;
    font-weight: 900;
    color: inherit;
    text-decoration: none;
    overflow: hidden;

    @include mobile {
      padding: 0 12px;
      font-size: 24px;
    }

    &:hover {

      &:after {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1, 1);
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 10px;
      background-color: #111;
      z-index: -1;
      
      transition: .4s;
      transform: translate(-50%, -50%) scale(0.2, 1);
      opacity: 0;

      @include mobile {
        height: 5px;
      }
    }
  }

  &.is-visible {
    opacity: 1;
    pointer-events: all;
    z-index: 5;
  }
}