.departments {
  @extend %dots;
  height: auto !important ;

  padding-left: 160px;
  overflow-x: hidden;

  @include tablet {
    padding: 60px 60px 150px;
  }
  @include mobile {
    padding: 50px 16px 50px;
  }

  .section__inner {
    display: flex;
    padding-right: 100px;
    flex-shrink: 0;

    @include tablet {
      flex-direction: column;
      padding-right: 0;
    }
    
    @include mobile {
      padding-right: 0;
    }
  }
  &__info {
    margin-top: auto;
    margin-bottom: auto;
  }
  &__title {
    position: relative;
    display: inline-block;
    margin-bottom: 23px;
    font-size: 42px;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    z-index: 1;

    @include mobile {
      display: table;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 24px;
    }
    
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      width: 300px;
      height: 2px;
      background-color: $c-grey;
      margin-right: 40px;
      right: calc(100%);

      @include mobile {
        display: none;
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: -5px;
      bottom: 6px;
      width: 105%;
      width: calc(100% + 10px);
      height: 10px;
      background-color: $c-accent;
      z-index: -1;

      @extend %shift-right;
      opacity: 0;
      transition: all .3s .2s;

      .animate & {
        opacity: 1;
        transform: none;
      }

      @include mobile {
        bottom: 0;
      }
    }
  }
  &__text {
    max-width: 240px;
    font-size: 18px;
    line-height: 1.44;
    color: #acacac;
    letter-spacing: 0.2px;

    @extend %shift-right;
    opacity: 0;
    transition: all .4s .3s;

    .animate & {
      opacity: 1;
      transform: none;
    }

    @include mobile {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__slider {
    @extend %swiper-container;
    margin: 0 auto;
    width: 590px;
    padding-bottom: 50px;
    
    @extend %shift-bottom;
    opacity: 0;
    transition: all .4s .15s;

    .animate & {
      opacity: 1;
      transform: none;
    }

    @include tablet {
      width: 100%;
      margin-top: 56px;
    }
    @include mobile {
      
    }
  }
  &__slider-inner {
    @extend %swiper-wrapper;
  }
  &__slide {
    @extend %swiper-slide;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 76px 40px 40px;
    z-index: 1;
    perspective: 1000px;
    user-select: none;

    @include tablet {
      height: auto;
    }
  }
  &__slide-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 5px solid $c-accent;
    z-index: -1;
    user-select: none;
    
    // overlay
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 500px rgba(#000, .6);
    }
  }
  &__slide-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  &__slide-title {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 34px;
  }
  &__slide-text {
    max-width: 150px;
    margin-bottom: 60px;
    font-size: 14px;
    line-height: 1.5;
    color: #acacac;
  }
}