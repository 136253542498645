.solutions {
  @extend %dots--white;
  // background-color: #dadada;
  background-color: #bbb;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  overflow: hidden;

  @include tablet {
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 60px;
  }
  @include mobile {
    padding-top: 50px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__header {
    position: relative;
    display: table;
    // display: flex;
    margin: 0 auto 64px;
    // margin-left: auto;
    // margin-right: auto;
    // margin-bottom: 64px;
    justify-content: center;

    @include mobile {
      margin-bottom: 60px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: calc(100% + 80px);
      top: 50%;
      width: 100vw;
      height: 2px;
      background-color: #dcdcdc;

      @include tablet {
        left: calc(100% + 30px);
      }
      @include tablet {
        left: calc(100% + 24px);
      }
    }
    &:after {
      left: auto;
      right: calc(100% + 80px);

      @include tablet {
        right: calc(100% + 30px);
      }
      @include mobile {
        right: calc(100% + 24px);
      }

    }
  }
  &__title {
    position: relative;
    text-align: center;
    letter-spacing: 1px;
    font-size: 42px;
    font-weight: 900;
    text-transform: uppercase;
    z-index: 1;

    @include mobile {
      font-size: 24px;
    }

    &:after {
      content: "";
      position: absolute;
      left: -5px;
      bottom: 6px;
      width: 105%;
      width: calc(100% + 10px);
      height: 10px;
      background-color: $c-accent;
      z-index: -1;

      @extend %shift-bottom;
      opacity: 0;
      transition: all .35s .1s;

      .animate & {
        opacity: 1;
        transform: none;
      }

      @include mobile {
        bottom: 0;
      }
    }
  }

  &__slider {
    @extend %swiper-container;
    margin: 0 auto;
    width: 950px;
    // max-width: 100%;
    padding-bottom: 50px;

    @extend %shift-bottom;
    opacity: 0;
    transition: all .4s .15s;

    .animate & {
      opacity: 1;
      transform: none;
    }

    @include tablet {
      width: 100%;
      display: block;
      flex-grow: 0;
      margin-bottom: 150px;
      // overflow: hidden;
      // background-color: red;
    }
  }
  &__slider-inner {
    @extend %swiper-wrapper;
  }
  &__slide {
    @extend %swiper-slide;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 56px 40px 40px;
    z-index: 1;
    perspective: 1500px;
    user-select: none;

    @include tablet {
      height: auto;
    }
  }
  &__slide-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 5px solid $c-accent;
    z-index: -1;
    user-select: none;

    &--whitened {

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 600px rgba(#fff, 0.5);
      }
    }
  }
  &__slide-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  &__slide-title {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 42px;
    letter-spacing: 0.2px;
  }
  &__slide-text {
    font-size: 14px;
    line-height: 1.5;
    color: #666;
    letter-spacing: 0.2px;
  }
}