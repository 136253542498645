.industries {
  @extend %dots;
  padding-left: 160px;
  overflow-x: hidden;

  @include tablet {
    padding-top: 50px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 90px;
    // min-height: auto;
  }
  @include mobile {
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .section__inner {
    display: flex;
    padding-right: 100px;

    @include tablet {
      padding-right: 0;
      flex-direction: column;
      align-items: center;
    }

    @include mobile {

    }
  }

  &__info {
    margin-top: auto;
    margin-bottom: auto;
  }
  &__title {
    position: relative;
    display: inline-block;
    margin-bottom: 23px;
    font-size: 42px;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    z-index: 1;

    @include tablet {
      
    }
    @include mobile {
      display: table;
      margin-left: auto;
      margin-right: auto;
      font-size: 24px;
      margin-bottom: 11px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      width: 300px;
      height: 2px;
      margin-right: 40px;
      background-color: $c-grey;

      @include tablet {
        display: none;
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: -5px;
      bottom: 6px;
      width: calc(100% + 10px);
      height: 10px;
      background-color: $c-accent;
      z-index: -1;

      @extend %shift-right;
      opacity: 0;
      transition: all .3s .2s;

      .animate & {
        opacity: 1;
        transform: none;
      }

      @include mobile {
        bottom: 0;
      }
    }
  }
  &__text {
    max-width: 240px;
    font-size: 18px;
    line-height: 1.44;
    color: #acacac;
    letter-spacing: 0.2px;

    @extend %shift-right;
    opacity: 0;
    transition: all .4s .3s;

    .animate & {
      opacity: 1;
      transform: none;
    }

    @include tablet {
      text-align: center;
      margin-bottom: 56px;
    }
  }
  &__slider {
    @extend %swiper-container;
    margin: 0 auto;
    width: 520px;
    padding-bottom: 50px;

    @extend %shift-bottom;
    opacity: 0;
    transition: all .4s .15s;

    .animate & {
      opacity: 1;
      transform: none;
    }

    @include tablet {
      width: 100%;
    }
  }
  &__slider-inner {
    @extend %swiper-wrapper;
  }
  &__slide {
    @extend %swiper-slide;
    display: flex;
    flex-direction: column;
    padding: 72px 80px 80px;
    box-sizing: border-box;
    z-index: 1;
    perspective: 1000px;
    user-select: none;

    @include tablet {

    }
    @include mobile {
      padding: 33px 38px 64px;
    }
  }
  &__slide-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    user-select: none;
    
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 1000px rgba(#000, .7);
    }
    &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: $c-accent;
      z-index: 2;
    }
  }
  &__slide-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  &__slide-title {
    margin-bottom: 44px;
    font-size: 36px;
    letter-spacing: 0.2px;
    font-weight: 900;

    @include tablet {
      
    }
    @include mobile {
      margin-bottom: 54px;
    }
  }
  &__slide-text {
    max-width: 360px;
    color: #acacac;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.2px;

    @include tablet {

    }
  }
}