// colors
  $c-black: #111;
  $c-grey: #808080;
  $c-purple: #341F97;
  $c-red: #ff0031;

  $c-main: $c-black;
  $c-accent: $c-red;
// eof colors

$f-stack: 'Roboto', arial, sans-serif;
$tr: all .3s;