.slider-nav {
  $sn: &;
  position: absolute;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  z-index: 2;

  &__fraction {
    margin: 0 20px;
  }
  &__current {
    
  }
  &__total {

  }
  &__arrow {
    width: 60px;
    height: 8px;
    padding: 10px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../img/slider-arrow.svg");
    transition: all .2s;
    cursor: pointer;
    opacity: .2;
    outline: none;

    &:hover {
      opacity: 1;
    }

    &--prev {
      transform: rotate(180deg);
    }
    &--next {
      
    }
  }

  &--left {
    justify-content: flex-start;

    @include tablet {
      justify-content: center;
    }
  }

  &--black {

    #{$sn}__arrow {
      background-image: url("../img/slider-arrow--black.svg");
    }
  }
}