.contacts {
  $c: &;
  position: relative;
  overflow-x: hidden;
  z-index: 3;

  @include tablet {
    padding-top: 50px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    width: calc(100% - 160px);
    height: calc(100% - 160px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../img/map.png");
    z-index: -1;

    transition: all .6s .45s;
    transform: translate(-50%, -47%) scale(0.95);
    opacity: 0;

    @include tablet {
      display: none;
    }
  }

  &.animate:after {
    transform: translate(-50%, -50%) scale(1);
    opacity: .2;
  }

  @include tablet {
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 67px;
  }
  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 27px;
  }

  .section__inner {
    margin: 0 auto;
    width: 900px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__header {
    position: relative;
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 60px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: calc(100% + 80px);
      top: 50%;
      width: 100vw;
      height: 2px;
      background-color: #dcdcdc;

      @include tablet {
        left: calc(100% + 30px);
      }
      @include tablet {
        left: calc(100% + 24px);
      }

    }
    &:after {
      left: auto;
      right: calc(100% + 80px);

      @include tablet {
        right: calc(100% + 30px);
      }
      @include mobile {
        right: calc(100% + 24px);
      }

    }
  }
  &__title {
    position: relative;
    text-align: center;
    letter-spacing: 1px;
    font-size: 42px;
    font-weight: 900;
    text-transform: uppercase;

    @include mobile {
      font-size: 24px;
    }

    &:after {
      content: "";
      position: absolute;
      left: -5px;
      bottom: 6px;
      width: 105%;
      width: calc(100% + 10px);
      height: 10px;
      background-color: $c-accent;
      z-index: -1;

      @extend %shift-bottom;
      opacity: 0;
      transition: all .35s .1s;

      .animate & {
        opacity: 1;
        transform: none;
      }

      @include mobile {
        bottom: 0;
      }
    }
  }
  &__grid {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 1025px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__part {
    width: 50%;

    @media (max-width: 1025px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    &--left {
      @extend %shift-left;
      opacity: 0;
      transition: all .4s .2s;

      .animate & {
        opacity: 1;
        transform: none;
      }
    }

    &--right {
      @extend %shift-right;
      opacity: 0;
      transition: all .4s .2s;

      .animate & {
        opacity: 1;
        transform: none;
      }
    }
  }
  &__info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include tablet {
      align-items: center;
      text-align: center;
    }
    @include mobile {
      align-items: flex-start;
    }
  }
  &__link {
    position: relative;
    margin-bottom: 48px;
    position: relative;
    text-decoration: none;
    color: inherit;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.2px;

    &:after {
      content: "";
      position: absolute;
      left: -4px; 
      bottom: 2px;
      width: 105%;
      width: calc(100% + 8px);
      height: 6px;
      background-color: $c-accent;
      z-index: -1;
    }
  }
  &__text {

    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @include mobile {
      align-items: flex-start;
    }
  }
  &__string {
    margin-bottom: 3px;
    line-height: 1.33;
    font-weight: bold;
    letter-spacing: 0.2px;
    font-size: 24px;
  }
  &__form {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 360px;

    @include tablet {
      margin-top: 40px;
      margin-right: auto;
    }

  }
  &__input {
    padding: 20px 11px;
    margin-bottom: 20px;
    background-color: transparent;
    border: none;
    color: $c-black;
    font-family: inherit;
    border-bottom: 2px solid $c-black;

    &::placeholder {
      color: #808080;
    }
  }
  &__textarea {
    height: 120px;
    padding: 20px 11px;
    margin-bottom: 20px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid $c-black;
    font-family: inherit;
    color: $c-black;
    resize: none;

    &::placeholder {
      color: #808080;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-top: 16px;
    letter-spacing: 1.5px;
    color: #fff;
    background-color: $c-accent;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background-color: darken($c-accent, 5%);
    }
  }
  &__bottom {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 35px;
    padding-left: 80px;
    padding-right: 80px;
    z-index: 2;
    pointer-events: none;
    display: flex;
    justify-content: space-between;

    @include tablet {
      position: static;
      padding: 0;
      margin-top: 60px;
      margin-bottom: 30px;
    }
    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__bottom-social {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: bold;
    pointer-events: all;
    transition: all .3s;

    &:hover {
      color: $c-red;
    }

    &:before {
      content: "";
      width: 20px;
      height: 2px;
      margin-right: 20px;
      background-color: #808080;
    }
  }
  &__bottom-scroll {
    pointer-events: all;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: inherit;
    text-decoration: none;

    &:hover {
      
      #{$c}__bottom-icon {
        border-color: $c-red;
      }
    }
  }
  &__bottom-label {
    margin-right: 18px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: bold;
  }
  &__bottom-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid $c-black;
    transform: rotate(180deg);
    transition: all .3s;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../img/arrow-down--black.svg");
      animation: jump 3s linear infinite;
    }
  }
  &__footer {
    position: absolute;
    left: 50%;
    bottom: 48px;
    transform: translateX(-50%);
    letter-spacing: 0.25px;
    color: #acacac;

    @include tablet {
      position: static;
      margin-top: 70px;
      transform: none;
    }
    @include mobile {
      margin-top: 0;
    }
  }
}