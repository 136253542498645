.greeting {
  $g: &;
  position: relative;
  padding-left: 280px;
  overflow-x: hidden;

  @include mobile {
    min-height: 100vh;
  }
  
  &__inner {
    z-index: 1;
    @extend %shift-left;
    opacity: 0;
    transition: all .5s .7s;

    .animate & {
      transform: none;
      opacity: 1;
    }
  }

  &:after {
    @extend %dots-png;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .5;
  }

  @include tablet {
    padding-left: 70px;
  }
  @include mobile {
    padding-left: 40px;
  }
  
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .section__inner {
    position: relative;
  }
  &__pretitle {
    font-size: 18px;
    line-height: 1.16;
    color: $c-grey;
    letter-spacing: 0.5px;
    font-weight: 500;
    margin-bottom: 20px;

    @include tablet {

    }
  }
  &__title {
    position: relative;
    max-width: 530px;
    margin-bottom: 30px;
    font-size: 72px;
    line-height: 1.16;
    letter-spacing: 4px;
    font-weight: 900;
    text-transform: uppercase;
    z-index: 1;

    @include mobile {
      max-width: 250px;
      font-size: 32px;
      letter-spacing: 1.7px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 40px;
      width: 300px;
      height: 2px;
      background-color: $c-grey;
      margin-right: 40px;
      right: 100%;

      @include tablet {
        margin-right: 20px;
      }
      @include mobile {
        margin-right: 16px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: -10px;
      bottom: 8px;
      width: 450px;
      height: 20px;
      background-color: $c-accent;
      z-index: -1;

      @extend %shift-left;
      opacity: 0;
      transition: all .3s .9s;

      .animate & {
        opacity: 1;
        transform: none;
      }

      @include mobile {
        bottom: 0;
      }

      @include mobile {
        width: 220px;
        bottom: 0;
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1.5px;
    line-height: 1.15;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {

      &:before {
        background-color: $c-red;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 40px;
      height: 2px;
      margin-right: 20px;
      background-color: #fff;
      transition: all .3s;
    }
  }

  &__bottom {
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 40px;
    padding-left: 80px;
    padding-right: 80px;
    z-index: 2;
    pointer-events: none;
    display: flex;
    justify-content: space-between;

    margin-bottom: -100px;
    animation: reveal-bottom .5s 1s linear forwards;

    @include tablet {
      display: none;
    }

    &--alt {
      color: $c-black;

      #{$g}__bottom-social {
        color: $c-black;
      }
      #{$g}__bottom-icon {
        border-color: $c-black;

        &:after {
          background-image: url("../img/arrow-down--black.svg");
        }
      }

    }
  }

  &__bottom-social {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    font-size: 11px;
    font-weight: bold;
    pointer-events: all;
    transition: all .3s;

    &:hover {
      color: $c-red;
    }

    &:before {
      content: "";
      width: 20px;
      height: 2px;
      margin-right: 20px;
      background-color: #808080;
    }
  }
  &__bottom-scroll {
    display: flex;
    align-items: center;
    pointer-events: all;
    cursor: pointer;
    
    &:hover {
      
      #{$g}__bottom-icon {
        border-color: $c-red;
      }
    }

    &--desk {
      
    }
    &--mob {
      
    }
  }
  a {
    color: #fff;
    text-decoration: none !important;
    outline: none;
  }
  &__bottom-label {
    margin-right: 18px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
  }
  &__bottom-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    transition: all .3s;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../img/arrow-down.svg");
      animation: jump 3s linear infinite;
      transition: all .3s .5s;
    }
  }
}